/* eslint-disable react/no-array-index-key */
import React, { Fragment, lazy, Suspense, useContext } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';

import { GlobalStateProvider } from 'src';
import { Roles } from 'src/generated/graphql';
import AuthGuard from 'src/components/AuthGuard';
import GuestGuard from 'src/components/GuestGuard';
import PublicAuthGuard from 'src/components/PublicAuthGuard';
import * as Sentry from '@sentry/react';
import DashboardLayout from './layouts/DashboardLayout';
import PublicLayout from './layouts/PublicLayout';
import PublicLMapLayout from './layouts/PublicLMapLayout';
import { AppStore } from './store';

const SentryRoute = Sentry.withSentryRouting(Route);

const availabilityDashboardUsers = ['cknk4bby7695930z9xqecks1m', '13', 'ckuwhalvp15628311egrnzbsf3'];

const routesConfig = [
    {
        exact: true,
        path: '/404',
        component: lazy(() => import('src/scenes/Error404View'))
    },
    {
        path: '/offices/:dealId',
        guard: PublicAuthGuard,
        routes: [
            {
                exact: true,
                path: '/offices/:dealId/compare',
                component: lazy(() => import('src/scenes/Public/Compare/Compare'))
            },
            {
                exact: true,
                path: [
                    '/offices/:dealId',
                    '/offices/:dealId/:officeStatus',
                    '/offices/:dealId/office/:initialPropertyId'
                ],
                layout: PublicLMapLayout,
                component: lazy(() => import('src/scenes/Public/Map/MapScene'))
            },
        ],
    },
    {
        path: '/viewing-map/:dealId',
        guard: PublicAuthGuard,
        routes: [
            {
                exact: true,
                path: '/viewing-map/:dealId',
                component: lazy(() => import('src/scenes/ViewingMap/ViewingMap'))
            },
        ],
    },
    {
        exact: true,
        layout: PublicLayout,
        path: '/providerRegister/:registerId/:status',
        component: lazy(() => import('src/scenes/Public/ProviderRegister/ProviderRegister'))
    },
    {
        exact: true,
        guard: GuestGuard,
        path: '/login',
        component: lazy(() => import('src/scenes/Login/Login'))
    },
    {
        path: '/app',
        guard: AuthGuard,
        layout: DashboardLayout,
        routes: [
            {
                exact: true,
                path: '/app',
                component: () => <Redirect to="/app/reports/dashboard" />
            },
            {
                exact: true,
                path: '/app/reports/dashboard',
                component: lazy(() => import('src/scenes/Dashboard/Dashboard'))
            },
            {
                exact: true,
                path: '/app/clients',
                component: lazy(() => import('src/scenes/Client/Listing/Clients'))
            },
            {
                exact: true,
                path: '/app/clients/:clientId',
                component: lazy(() => import('src/scenes/Client/Single/Client'))
            },
            {
                exact: true,
                path: '/app/deals',
                component: lazy(() => import('src/scenes/Deal/Listing/Deals'))
            },
            {
                exact: true,
                path: '/app/deals/:dealId',
                component: lazy(() => import('src/scenes/Deal/Single/Deal'))
            },
            {
                exact: true,
                path: '/app/deals/map/:dealId',
                component: lazy(() => import('src/scenes/Deal/Map/Deal'))
            },
            {
                exact: true,
                path: '/app/deals/offices/:dealId',
                component: lazy(() => import('src/scenes/Deal/Offices/Offices'))
            },
            {
                exact: true,
                path: '/app/providers',
                component: lazy(() => import('src/scenes/Provider/Listing/Providers'))
            },
            {
                exact: true,
                path: '/app/providers/:providerId',
                component: lazy(() => import('src/scenes/Provider/Single/Provider'))
            },
            {
                exact: true,
                path: '/app/offices',
                component: lazy(() => import('src/scenes/Office/Listing/Offices'))
            },
            {
                exact: true,
                path: '/app/properties/map',
                component: lazy(() => import('src/scenes/Property/Map/Map'))
            },
            {
                exact: true,
                path: '/app/properties/:propertyId',
                component: lazy(() => import('src/scenes/Property/Single/Property'))
            },
            {
                exact: true,
                path: '/app/scraper',
                component: lazy(() => import('src/scenes/Admin/Scraper/Scraper'))
            },
            {
                component: () => <Redirect to="/404" />
            }
        ]
    },
    {
        exact: true,
        path: [
            '/public/available-properties',
            '/public/available-properties/:propertyRegion/:propertyName/:propertyId'
        ],
        component: lazy(() => import('src/scenes/Public/AvailableProperties/AvailableProperties'))
    },
    {
        exact: true,
        path: '/public/available-properties/:propertyRegion/:propertyName/:propertyId/brochure',
        component: lazy(() => import('src/scenes/Public/AvailableProperties/components/Brochure/Brochure'))
    },
    {
        exact: true,
        path: [
            '/public/top-available-properties/:propertyId',
        ],
        component: lazy(() => import('src/scenes/Public/TopAvailableProperties/TopAvailableProperties'))
    },
];

const adminRoutesConfig = [
    ...routesConfig,
    {
        path: '/admin',
        guard: AuthGuard,
        layout: DashboardLayout,
        routes: [
            {
                exact: true,
                path: '/admin/broker',
                component: lazy(() => import('src/scenes/Admin/Broker/Listing/Broker'))
            },
            {
                exact: true,
                path: '/admin/region',
                component: lazy(() => import('src/scenes/Admin/Region/Listing/Region'))
            },
            {
                exact: true,
                path: '/admin/scraper',
                component: lazy(() => import('src/scenes/Admin/Scraper/Scraper'))
            },
            {
                exact: true,
                path: '/admin/rentmap',
                component: lazy(() => import('src/scenes/Rentmap/Rentmap'))
            }
        ]
    }
];

const availabilityAdminRoutesConfig = [
    ...routesConfig,
    {
        path: '/admin',
        guard: AuthGuard,
        layout: DashboardLayout,
        routes: [
            {
                exact: true,
                path: '/admin/broker',
                component: lazy(() => import('src/scenes/Admin/Broker/Listing/Broker'))
            },
            {
                exact: true,
                path: '/admin/region',
                component: lazy(() => import('src/scenes/Admin/Region/Listing/Region'))
            },
            {
                exact: true,
                path: '/admin/scraper',
                component: lazy(() => import('src/scenes/Admin/Scraper/Scraper'))
            },
            {
                exact: true,
                path: '/admin/rentmap',
                component: lazy(() => import('src/scenes/Rentmap/Rentmap'))
            },
            {
                exact: true,
                path: '/admin/availability-dashboard',
                component: lazy(() => import('src/scenes/AvailabilityDashboard/AvailabilityDashboard'))
            },
            {
                component: () => <Redirect to="/404" />
            }
        ]
    }
];

const loaderRoutesConfig = [
    {
        exact: true,
        path: '/404',
        component: lazy(() => import('src/scenes/Error404View'))
    },
    {
        exact: true,
        guard: GuestGuard,
        path: '/login',
        component: lazy(() => import('src/scenes/Login/Login'))
    },
    {
        path: '/app',
        guard: AuthGuard,
        layout: DashboardLayout,
        routes: [
            {
                exact: true,
                path: '/app/providers',
                component: lazy(() => import('src/scenes/Provider/Listing/Providers'))
            },
            {
                exact: true,
                path: '/app/providers/:providerId',
                component: lazy(() => import('src/scenes/Provider/Single/Provider'))
            },
            {
                exact: true,
                path: '/app/offices',
                component: lazy(() => import('src/scenes/Office/Listing/Offices'))
            },
            {
                exact: true,
                path: '/app/properties/map',
                component: lazy(() => import('src/scenes/Property/Map/Map'))
            },
            {
                exact: true,
                path: '/app/properties/:propertyId',
                component: lazy(() => import('src/scenes/Property/Single/Property'))
            },
            {
                component: () => <Redirect to="/app/providers" />
            }
        ]
    },
];

const disposalRoutesConfig = [
    {
        exact: true,
        path: '/404',
        component: lazy(() => import('src/scenes/Error404View'))
    },
    {
        path: '/offices/:dealId',
        guard: PublicAuthGuard,
        routes: [
            {
                exact: true,
                path: '/offices/:dealId/compare',
                component: lazy(() => import('src/scenes/Public/Compare/Compare'))
            },
            {
                exact: true,
                path: [
                    '/offices/:dealId',
                    '/offices/:dealId/:officeStatus',
                    '/offices/:dealId/office/:initialPropertyId'
                ],
                layout: PublicLMapLayout,
                component: lazy(() => import('src/scenes/Public/Map/MapScene'))
            },
        ],
    },
    {
        exact: true,
        layout: PublicLayout,
        path: '/providerRegister/:registerId/:status',
        component: lazy(() => import('src/scenes/Public/ProviderRegister/ProviderRegister'))
    },
    {
        exact: true,
        guard: GuestGuard,
        path: '/login',
        component: lazy(() => import('src/scenes/Login/Login'))
    },
    {
        path: '/app',
        guard: AuthGuard,
        layout: DashboardLayout,
        routes: [
            {
                exact: true,
                path: '/app',
                component: () => <Redirect to="/app/reports/dashboard" />
            },
            {
                exact: true,
                path: '/app/reports/dashboard',
                component: lazy(() => import('src/scenes/Dashboard/Dashboard'))
            },
            {
                exact: true,
                path: '/app/deals/:dealId',
                component: lazy(() => import('src/scenes/Deal/Single/Deal'))
            },
            {
                exact: true,
                path: '/app/deals/map/:dealId',
                component: lazy(() => import('src/scenes/Deal/Map/Deal'))
            },
            {
                exact: true,
                path: '/app/deals/offices/:dealId',
                component: lazy(() => import('src/scenes/Deal/Offices/Offices'))
            },
            {
                exact: true,
                path: '/app/providers',
                component: lazy(() => import('src/scenes/Provider/Listing/Providers'))
            },
            {
                exact: true,
                path: '/app/providers/:providerId',
                component: lazy(() => import('src/scenes/Provider/Single/Provider'))
            },
            {
                exact: true,
                path: '/app/offices',
                component: lazy(() => import('src/scenes/Office/Listing/Offices'))
            },
            {
                exact: true,
                path: '/app/properties/map',
                component: lazy(() => import('src/scenes/Property/Map/Map'))
            },
            {
                exact: true,
                path: '/app/properties/:propertyId',
                component: lazy(() => import('src/scenes/Property/Single/Property'))
            },
            {
                exact: true,
                path: '/app/scraper',
                component: lazy(() => import('src/scenes/Admin/Scraper/Scraper'))
            },
            {
                component: () => <Redirect to="/404" />
            },
        ],
    },
    {
        exact: true,
        path: [
            '/public/available-properties',
            '/public/available-properties/:propertyRegion/:propertyName/:propertyId'
        ],
        component: lazy(() => import('src/scenes/Public/AvailableProperties/AvailableProperties'))
    },
    {
        exact: true,
        path: '/public/available-properties/:propertyRegion/:propertyName/:propertyId/brochure',
        component: lazy(() => import('src/scenes/Public/AvailableProperties/components/Brochure/Brochure'))
    },
    {
        exact: true,
        path: [
            '/public/top-available-properties/:propertyId',
        ],
        component: lazy(() => import('src/scenes/Public/TopAvailableProperties/TopAvailableProperties'))
    },
];

const renderRoutes = (routes: any) => (routes ? (
    // <Suspense fallback={<LoadingScreen />}>
    <Suspense fallback={<div />}>
        <Switch>
            {routes.map((route: any, i: number) => {
                const Guard = route.guard || Fragment;
                const Layout = route.layout || Fragment;
                const Component = route.component;

                return (
                    <SentryRoute
                        key={JSON.stringify(i)}
                        path={route.path}
                        exact={route.exact}
                        render={(props) => (
                            <Guard>
                                <Layout>
                                    {route.routes
                                        ? renderRoutes(route.routes)
                                        : <Component {...props} />}
                                </Layout>
                            </Guard>
                        )}
                    />
                );
            })}
        </Switch>
    </Suspense>
) : null);

function Routes() {
    const appStore = useContext<AppStore>(GlobalStateProvider);
    const currentUser = appStore.authStore.getUser();

    let routes: any = routesConfig;
    if (currentUser?.role === Roles.Loader) {
        routes = loaderRoutesConfig;
    }
    if (currentUser?.role === Roles.Admin || currentUser?.role === Roles.Superadmin) {
        if (availabilityDashboardUsers.includes(currentUser.id)) {
            routes = availabilityAdminRoutesConfig;
        } else {
            routes = adminRoutesConfig;
        }
    }
    if (currentUser?.role === Roles.Disposal) {
        routes = disposalRoutesConfig;
    }

    return renderRoutes(routes);
}

export default Routes;
